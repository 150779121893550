import { Flex, FormControl, FormErrorMessage, Text } from "@chakra-ui/react";
import { getIn, useFormikContext } from "formik";
import isEmpty from "lodash/isEmpty";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EFieldType } from "components/formField/FormField";
import { Property } from "components/property";
import TogglableFormPart from "components/TogglableFormPart/TogglableFormPart";
import StoreField from "pages/store/components/StoreField";
import StoreFieldArray from "pages/store/components/StoreFieldArray";
import {
  emptyCardIssuerAction,
  emptyPrefixConfig,
  getSmsFields,
  purchaseConfirmationDocTypesOptions,
  smsOptions,
  cardIssuerActionTypeOptions,
  defaultChannelOptions,
  prefixChannelOptions,
  emptyClientPriority,
  emptyActivation
} from "pages/store/consts";
import {
  ECardIssuerActions,
  ECLientPriority,
  EIssuerActionProperty,
  EIssuerActionType,
  EParameterProperty,
  EParameters,
  EPrefixConfigFields,
  EPrefixFields,
  EStore,
  TStore
} from "types/configFields";

import { label, headers } from "../../labels";
import { cardStyles } from "../../styles";

import DailyPeriodRules from "./DailyPeriodRules";
import OrdersRules from "./OrdersRules";
import PeriodRules from "./PeriodRules";

const Parameters = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext<TStore>();
  const cardIssuerActionsKey = `${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`;
  const ordersRules =
    values?.[EStore.PARAMETERS]?.[EParameters.CLIENT_PRIORITY]?.[
      ECLientPriority.ORDERS_RULES
    ] || [];
  const dailyPeriodRules =
    values?.[EStore.PARAMETERS]?.[EParameters.CLIENT_PRIORITY]?.[
      ECLientPriority.DAILY_PERIOD_RULES
    ] || [];
  const periodRules =
    values?.[EStore.PARAMETERS]?.[EParameters.CLIENT_PRIORITY]?.[
      ECLientPriority.PERIOD_RULES
    ] || [];

  const activationError = getIn(
    errors,
    `${EStore.PARAMETERS}.${EParameters.ACTIVATION}`
  );
  const activationTouched = getIn(
    touched,
    `${EStore.PARAMETERS}.${EParameters.ACTIVATION}`
  );
  const hasAtLeastOneActivationFlagTouched = !isEmpty(activationTouched);

  return (
    <>
      <BorderedBox mt="0">
        <Flex {...cardStyles}>
          <Text
            data-searchid={`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}`}
            fontSize="1rem"
            fontWeight="bold"
            m="0"
            mb="2rem"
          >
            {
              headers[
                `${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}`
              ]
            }
          </Text>

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.DOMAIN}`}
          />
          <StoreField
            fieldType={EFieldType.SELECT}
            name={`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.TYPE}`}
            options={purchaseConfirmationDocTypesOptions}
          />

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.DEFAULT_LANGUAGE}`}
          />

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.COUNTRY_CODE}`}
          />

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.DEFAULT_PREAUTH_AMOUNT}`}
          />

          <TogglableFormPart
            name={`${EStore.PARAMETERS}.${EParameters.APP_PREAUTH_ENABLED}`}
            headerText=""
            emptyInitialValue={false}
          >
            <StoreField
              fieldType={EFieldType.CHECKBOX}
              name={`${EStore.PARAMETERS}.${EParameters.APP_PREAUTH_ENABLED}`}
            />
          </TogglableFormPart>

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.CURRENCY}`}
          />
        </Flex>
      </BorderedBox>

      <BorderedBox mt="0">
        <TogglableFormPart
          name={`${EStore.PARAMETERS}.${EParameters.ACTIVATION}`}
          headerText={headers[`${EStore.PARAMETERS}.${EParameters.ACTIVATION}`]}
          emptyInitialValue={emptyActivation}
        >
          <FormControl
            isInvalid={
              hasAtLeastOneActivationFlagTouched && Boolean(activationError)
            }
          >
            <Flex gap="1rem" flexDir="column">
              <StoreField
                fieldType={EFieldType.CHECKBOX}
                name={`${EStore.PARAMETERS}.${EParameters.ACTIVATION}.${EParameterProperty.REQUIRE_FOR_EXISTING_CLIENTS}`}
              />

              <StoreField
                fieldType={EFieldType.CHECKBOX}
                name={`${EStore.PARAMETERS}.${EParameters.ACTIVATION}.${EParameterProperty.REQUIRE_FOR_NEW_CLIENTS}`}
              />
            </Flex>
            <FormErrorMessage mt="1rem">
              {String(activationError)}
            </FormErrorMessage>
          </FormControl>
        </TogglableFormPart>
      </BorderedBox>

      <BorderedBox mt="0">
        <Text
          data-searchid={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}`}
          fontSize="1rem"
          fontWeight="bold"
          mb="1rem"
        >
          {
            headers[
              `${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}`
            ]
          }
        </Text>
        <StoreField
          fieldType={EFieldType.SELECT}
          name={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.DEFAULT_CHANNEL}`}
          options={defaultChannelOptions}
        />

        <StoreFieldArray
          name={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}`}
          header={
            headers[
              `${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}`
            ]
          }
          addButtonText="Dodaj nową konfigurację prefixów"
          emptyArrayText="Brak konfiguracji prefixów"
          emptyItem={emptyPrefixConfig}
          renderComponent={(_, index) => (
            <>
              <StoreField
                isSimpleField
                fieldType={EFieldType.TEXT}
                name={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.${index}.${EPrefixFields.PREFIX}`}
                labelOverride={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.0.${EPrefixFields.PREFIX}`}
              />
              <StoreField
                isSimpleField
                fieldType={EFieldType.SELECT}
                name={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.${index}.${EPrefixFields.CHANNEL}`}
                labelOverride={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.0.${EPrefixFields.CHANNEL}`}
                options={prefixChannelOptions}
              />
            </>
          )}
          renderDisabledComponent={(item, index) => (
            <>
              <Property
                data-searchid={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.${index}.${EPrefixFields.PREFIX}`}
                label={
                  label[
                    `${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.0.${EPrefixFields.PREFIX}` as keyof typeof label
                  ]
                }
              >
                {item.prefix}
              </Property>
              <Property
                data-searchid={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.${index}.${EPrefixFields.CHANNEL}`}
                label={
                  label[
                    `${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.0.${EPrefixFields.CHANNEL}` as keyof typeof label
                  ]
                }
              >
                {item.channel}
              </Property>
            </>
          )}
        />
      </BorderedBox>

      <BorderedBox mt="0">
        <Text
          data-searchid={`${EStore.PARAMETERS}.${EParameters.SMS}`}
          fontSize="1rem"
          fontWeight="bold"
          m="0"
          mb="2rem"
        >
          {headers[`${EStore.PARAMETERS}.${EParameters.SMS}`]}
        </Text>
        <Flex gap="1rem" columnGap="3rem" flexWrap="wrap">
          {getSmsFields().map(({ smsKey, thresholdKey }) => (
            <BorderedBox
              minWidth="20rem"
              maxWidth="20rem"
              key={smsKey}
              display="flex"
              flexDir="column"
              gap="2rem"
              border={thresholdKey ? undefined : "none"}
            >
              <StoreField
                fieldType={EFieldType.SELECT}
                name={`${EStore.PARAMETERS}.${EParameters.SMS}.${smsKey}`}
                options={smsOptions[smsKey]}
              />
              {thresholdKey && (
                <StoreField
                  fieldType={EFieldType.TEXT}
                  name={`${EStore.PARAMETERS}.${EParameters.SMS}.${thresholdKey}`}
                />
              )}
            </BorderedBox>
          ))}
        </Flex>
      </BorderedBox>

      <BorderedBox>
        <StoreFieldArray
          name={cardIssuerActionsKey}
          header={headers[cardIssuerActionsKey]}
          addButtonText="Dodaj nową konfigurację akcji"
          emptyArrayText="Brak konfiguracji akcji"
          emptyItem={emptyCardIssuerAction}
          renderComponent={(item, index) => (
            <>
              <StoreField
                isSimpleField
                fieldType={EFieldType.TEXT}
                name={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.BINS}`}
                labelOverride={`${cardIssuerActionsKey}.0.${ECardIssuerActions.BINS}`}
                options={cardIssuerActionTypeOptions}
              />
              <StoreField
                isSimpleField
                fieldType={EFieldType.TEXT}
                name={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.BRANDS}`}
                labelOverride={`${cardIssuerActionsKey}.0.${ECardIssuerActions.BRANDS}`}
                options={cardIssuerActionTypeOptions}
              />
              <StoreField
                isSimpleField
                fieldType={EFieldType.TEXT}
                name={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.COUNTRY_CODES}`}
                labelOverride={`${cardIssuerActionsKey}.0.${ECardIssuerActions.COUNTRY_CODES}`}
                options={cardIssuerActionTypeOptions}
              />

              <StoreField
                isSimpleField
                fieldType={EFieldType.SELECT}
                name={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.TYPE}`}
                labelOverride={`${cardIssuerActionsKey}.0.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.TYPE}`}
                options={cardIssuerActionTypeOptions}
                extendOnChange={value => {
                  if (value === EIssuerActionType.DENY_ENTRY) {
                    setFieldValue(
                      `${cardIssuerActionsKey}.${index}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`,
                      ""
                    );
                  }
                }}
              />

              {item.action.type === EIssuerActionType.OVERRIDE_PREAUTH && (
                <StoreField
                  isSimpleField
                  fieldType={EFieldType.TEXT}
                  name={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`}
                  labelOverride={`${cardIssuerActionsKey}.0.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`}
                />
              )}
            </>
          )}
          renderDisabledComponent={(item, index) => (
            <>
              <Property
                data-searchid={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.BINS}`}
                label={
                  label[
                    `${cardIssuerActionsKey}.0.${ECardIssuerActions.BINS}` as keyof typeof label
                  ]
                }
              >
                {item.bins}
              </Property>
              <Property
                data-searchid={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.BRANDS}`}
                label={
                  label[
                    `${cardIssuerActionsKey}.0.${ECardIssuerActions.BRANDS}` as keyof typeof label
                  ]
                }
              >
                {item.brands}
              </Property>
              <Property
                data-searchid={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.COUNTRY_CODES}`}
                label={
                  label[
                    `${cardIssuerActionsKey}.0.${ECardIssuerActions.COUNTRY_CODES}` as keyof typeof label
                  ]
                }
              >
                {item.countryCodes}
              </Property>
              <Property
                data-searchid={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.TYPE}`}
                label={
                  label[
                    `${cardIssuerActionsKey}.0.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.TYPE}` as keyof typeof label
                  ]
                }
              >
                {item.action.type}
              </Property>
              {item.action.type === EIssuerActionType.OVERRIDE_PREAUTH && (
                <Property
                  data-searchid={`${cardIssuerActionsKey}.${index}.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`}
                  label={
                    label[
                      `${cardIssuerActionsKey}.0.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}` as keyof typeof label
                    ]
                  }
                >
                  {item.action.preauthAmount}
                </Property>
              )}
            </>
          )}
        />
      </BorderedBox>

      <BorderedBox mt="0">
        <TogglableFormPart
          name={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}`}
          headerText={
            headers[`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}`]
          }
          emptyInitialValue={emptyClientPriority}
        >
          <OrdersRules ordersRules={ordersRules} />
          <DailyPeriodRules dailyPeriodRules={dailyPeriodRules} />
          <PeriodRules periodRules={periodRules} />

          <Text
            data-searchid={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.STORE_RULE}`}
            fontSize="0.8rem"
            fontWeight="bold"
            m="0"
            mt="2rem"
          >
            {
              headers[
                `${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.STORE_RULE}`
              ]
            }
          </Text>
          <BorderedBox
            display="flex"
            flexDirection="column"
            gap="1rem"
            w="100%"
          >
            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.STORE_RULE}.${ECLientPriority.PRIORITY}`}
            />
          </BorderedBox>

          <Text
            data-searchid={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}`}
            fontSize="0.8rem"
            fontWeight="bold"
            m="0"
            mt="2rem"
          >
            {
              headers[
                `${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}`
              ]
            }
          </Text>
          <BorderedBox
            display="flex"
            flexDirection="column"
            gap="1rem"
            w="100%"
          >
            <StoreField
              isDisabled
              fieldType={EFieldType.CHECKBOX}
              name={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}.${ECLientPriority.ENABLED}`}
            />
          </BorderedBox>
        </TogglableFormPart>
      </BorderedBox>
    </>
  );
};

export default Parameters;
