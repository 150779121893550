import {
  ECLientPriority,
  EComponent,
  EComponentProperty,
  EDay,
  EIssuerActionType,
  EMonitor,
  EParameterProperty,
  EParameterPropertySms,
  EParameters,
  EProperty,
  ESchedule,
  EStore,
  EStoreService,
  EStoreServiceProperty,
  EStoreState,
  EStoreType,
  TMonitor,
  TStore,
  TStoreComponents,
  TStoreParameters,
  TStoreServices,
  EPosType,
  EMessage,
  EMessageNonDisable,
  EPurchaseConfirmationDocType,
  EPrefixConfigFields,
  EParameterPropertySmsThreshold,
  EMaintenanceBreak,
  EMaintenanceBreakType,
  EPrefixFields,
  ECardIssuerActions,
  ECardIssuerAction
} from "types/configFields";

export const typesOptions = Object.values(EStoreType).map(value => ({
  value,
  label: value
}));

export const statesOptions = Object.values(EStoreState).map(value => ({
  value,
  label: value
}));

export const maintenanceBreakOptions = [
  { value: EMaintenanceBreakType.DAILY, label: EMaintenanceBreakType.DAILY },
  { value: EMaintenanceBreakType.YEARLY, label: EMaintenanceBreakType.YEARLY },
  {
    value: EMaintenanceBreakType.ONE_TIME,
    label: EMaintenanceBreakType.ONE_TIME
  }
];

export const purchaseConfirmationDocTypesOptions = Object.values(
  EPurchaseConfirmationDocType
).map(value => ({
  value,
  label: value
}));

export const booleanOptions = [
  { value: true, label: "TRUE" },
  { value: false, label: "FALSE" }
];

export const messageOptions = Object.entries(EMessage).map(([key, value]) => ({
  value,
  label: key
}));

export const messageNonDisableOptions = Object.entries(EMessageNonDisable).map(
  ([key, value]) => ({
    value,
    label: key
  })
);

export const componentTypesOptions = Object.values(EPosType).map(value => ({
  value,
  label: value
}));

export const cardIssuerActionTypeOptions = Object.values(EIssuerActionType).map(
  value => ({
    value,
    label: value
  })
);

export const smsOptions = {
  [EParameterPropertySms.WELCOME_SMS]: messageOptions,
  [EParameterPropertySms.RECEIPT_SMS]: messageOptions,
  [EParameterPropertySms.EXIT_SMS]: messageOptions,
  [EParameterPropertySms.PAYMENT_FAILED_SMS]: messageOptions,
  [EParameterPropertySms.PBL_REQUESTED_SMS]: messageNonDisableOptions,
  [EParameterPropertySms.ACTIVATION_SMS]: messageNonDisableOptions,
  [EParameterPropertySms.AUTO_RESCUE_INIT_SMS]: messageOptions,
  [EParameterPropertySms.FIRST_RESCUE_ATTEMPT_SMS]: messageOptions,
  [EParameterPropertySms.LAST_RESCUE_ATTEMPT_SMS]: messageOptions,
  [EParameterPropertySms.PAYMENT_REMINDER1_SMS]: messageOptions,
  [EParameterPropertySms.PAYMENT_REMINDER2_SMS]: messageOptions,
  [EParameterPropertySms.PAYMENT_REMINDER3_SMS]: messageOptions,
  [EParameterPropertySms.PAYMENT_REMINDER4_SMS]: messageOptions,
  [EParameterPropertySms.PAYMENT_REMINDER5_SMS]: messageOptions
};

export const getSmsFields = () => {
  return Object.values(EParameterPropertySms).map(smsKey => {
    const thresholdKey = Object.values(EParameterPropertySmsThreshold).find(
      threshold => threshold.startsWith(smsKey)
    );
    return {
      smsKey,
      thresholdKey
    };
  });
};

export const emptyMaintenanceBreak = {
  [EMaintenanceBreak.TYPE]: "",
  [EMaintenanceBreak.STARTS_AT]: "",
  [EMaintenanceBreak.ENDS_AT]: ""
};

export const emptyOrderRule = {
  [ECLientPriority.PRIORITY]: "",
  [ECLientPriority.ORDERS_THRESHOLD]: ""
};

export const emptyPeriodRule = {
  [ECLientPriority.STARTS_AT]: "",
  [ECLientPriority.ENDS_AT]: "",
  [ECLientPriority.PRIORITY]: ""
};

export const emptyDateValues = {
  [ESchedule.ADJUSTED_DAYS]: {
    [EProperty.DAY]: "",
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [ESchedule.CLOSED_DAYS]: ""
};

export const emptyClientPriority = {
  [ECLientPriority.ORDERS_RULES]: [],
  [ECLientPriority.DAILY_PERIOD_RULES]: [],
  [ECLientPriority.PERIOD_RULES]: [],
  [ECLientPriority.VIP_RULE]: {
    [ECLientPriority.ENABLED]: false
  },
  [ECLientPriority.STORE_RULE]: {
    [ECLientPriority.PRIORITY]: ""
  }
};

export const emptyActivation = {
  [EParameterProperty.REQUIRE_FOR_EXISTING_CLIENTS]: false,
  [EParameterProperty.REQUIRE_FOR_NEW_CLIENTS]: false
};

export const emptyPrefixConfig = {
  [EPrefixFields.PREFIX]: "",
  [EPrefixFields.CHANNEL]: ""
};

export const emptyCardIssuerAction = {
  [ECardIssuerActions.BINS]: "",
  [ECardIssuerActions.BRANDS]: "",
  [ECardIssuerActions.COUNTRY_CODES]: "",
  [ECardIssuerActions.ACTION]: {
    [ECardIssuerAction.TYPE]: "",
    [ECardIssuerAction.PREAUTH_AMOUNT]: ""
  }
};

export const channelOptions = [
  { value: "", label: "Brak" },
  { value: "SMS", label: "SMS" },
  { value: "WHATSAPP", label: "WHATSAPP" },
  { value: "DISABLED", label: "DISABLED" }
];

export const prefixChannelOptions = [
  { value: "SMS", label: "SMS" },
  { value: "WHATSAPP", label: "WHATSAPP" },
  { value: "DISABLED", label: "DISABLED" }
];

export const defaultChannelOptions = [
  { value: "", label: "Brak" },
  { value: "SMS", label: "SMS" },
  { value: "WHATSAPP", label: "WHATSAPP" }
];

export const emptyParameters: TStoreParameters = {
  [EParameters.PURCHASE_CONFIRMATION_DOC]: {
    [EParameterProperty.TYPE]: "",
    [EParameterProperty.DOMAIN]: ""
  },
  [EParameters.DEFAULT_LANGUAGE]: "",
  [EParameters.COUNTRY_CODE]: "",
  [EParameters.DEFAULT_PREAUTH_AMOUNT]: "",
  [EParameters.APP_PREAUTH_ENABLED]: null,
  [EParameters.CURRENCY]: "",
  [EParameters.ACTIVATION]: null,
  [EParameters.SMS]: {
    [EParameterProperty.PREFIX_CONFIG]: {
      [EPrefixConfigFields.DEFAULT_CHANNEL]: "",
      [EPrefixConfigFields.PREFIXES]: []
    },
    [EParameterPropertySms.WELCOME_SMS]: "",
    [EParameterPropertySms.RECEIPT_SMS]: "",
    [EParameterPropertySms.EXIT_SMS]: "",
    [EParameterPropertySms.PAYMENT_FAILED_SMS]: "",
    [EParameterPropertySms.PBL_REQUESTED_SMS]: "",
    [EParameterPropertySms.ACTIVATION_SMS]: "",
    [EParameterPropertySms.AUTO_RESCUE_INIT_SMS]: "",
    [EParameterPropertySms.FIRST_RESCUE_ATTEMPT_SMS]: "",
    [EParameterPropertySms.LAST_RESCUE_ATTEMPT_SMS]: "",
    [EParameterPropertySms.PAYMENT_REMINDER1_SMS]: "",
    [EParameterPropertySms.PAYMENT_REMINDER2_SMS]: "",
    [EParameterPropertySms.PAYMENT_REMINDER3_SMS]: "",
    [EParameterPropertySms.PAYMENT_REMINDER4_SMS]: "",
    [EParameterPropertySms.PAYMENT_REMINDER5_SMS]: "",
    [EParameterPropertySmsThreshold.AUTO_RESCUE_INIT_SMS_THRESHOLD]: "",
    [EParameterPropertySmsThreshold.FIRST_RESCUE_ATTEMPT_SMS_THRESHOLD]: "",
    [EParameterPropertySmsThreshold.LAST_RESCUE_ATTEMPT_SMS_THRESHOLD]: "",
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER1_SMS_THRESHOLD]: "",
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER2_SMS_THRESHOLD]: "",
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER3_SMS_THRESHOLD]: "",
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER4_SMS_THRESHOLD]: "",
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER5_SMS_THRESHOLD]: ""
  },
  [EParameters.CARD_ISSUER_ACTIONS]: [],
  [EParameters.CLIENT_PRIORITY]: null
};

const emptyMonitor: TMonitor = {
  [EMonitor.IS_ENABLED]: undefined,
  [EMonitor.IS_DISABLED_OUTSIDE_WORKING_HOURS]: undefined,
  [EMonitor.ACTIONS]: {
    [EMonitor.STORE_STATE_MODIFIER]: {
      [EMonitor.IS_ACTIVE]: undefined,
      [EMonitor.CLOSE_THRESHOLD]: "",
      [EMonitor.CLOSE_PERIOD]: "",
      [EMonitor.OPEN_THRESHOLD]: "",
      [EMonitor.OPEN_PERIOD]: ""
    }
  }
};

export const emptyComponents: TStoreComponents = {
  [EComponent.AIFI]: {
    [EComponentProperty.AIFI_ID]: "",
    [EComponentProperty.ENTRY_ID]: "",
    [EComponentProperty.MONITORS]: {
      [EComponentProperty.STATE]: emptyMonitor
    }
  },
  [EComponent.ADYEN]: {
    [EComponentProperty.TERMINAL_ID]: "",
    [EComponentProperty.MONITORS]: {
      [EComponentProperty.AVAILABILITY]: emptyMonitor
    }
  },
  [EComponent.POS]: {
    [EComponentProperty.SERVICE_KEY]: "",
    [EComponentProperty.TYPE]: "",

    [EComponentProperty.POS_SERVICE]: {
      [EComponentProperty.WORKER_ID]: "",
      [EComponentProperty.POS_IP_ADDRESS]: "",
      [EComponentProperty.POS_PORT]: "",
      [EComponentProperty.POS_USERNAME]: "",
      [EComponentProperty.POS_PASSWORD]: "",
      [EComponentProperty.OUTAGE_THRESHOLD]: "",
      [EComponentProperty.NO_OUTAGE_THRESHOLD]: "",
      [EComponentProperty.USE_STORE_COUPONS_POOL_LIST]: false,
      [EComponentProperty.IS_BIG_RED_BOX_PROMO_ENABLED]: false,
      [EComponentProperty.MAINTENANCE_BREAKS]: []
    },
    [EComponentProperty.IPOS]: {
      [EComponentProperty.TRONITAG_ID]: "",
      [EComponentProperty.FAKTUROWNIA_ID]: ""
    },
    [EComponentProperty.MONITORS]: {
      [EComponentProperty.AVAILABILITY]: emptyMonitor,
      [EComponentProperty.PAPER_STATE]: emptyMonitor
    }
  },
  [EComponent.INFOKIOSK]: {
    [EComponentProperty.SCOPE]: "",
    [EComponentProperty.SERVICE_KEY]: "",
    [EComponentProperty.HEARTBEAT_THRESHOLD]: "",
    [EComponentProperty.MONITORS]: {
      [EComponentProperty.AVAILABILITY]: emptyMonitor
    }
  },
  [EComponent.POWER_BOUNCER]: {
    [EComponentProperty.TOKEN]: "",
    [EComponentProperty.HELP_LINK]: "",
    [EComponentProperty.HEARTBEAT_THRESHOLD]: "",
    [EComponentProperty.AIFI_ZONE_ID]: "",
    [EComponentProperty.IO_CONTROLLER]: {
      [EComponentProperty.SERVICE_KEY]: ""
    },
    [EComponentProperty.AGE_ESTIMATION]: {
      [EComponentProperty.MIN_ADULT_AGE]: ""
    },
    [EComponentProperty.MONITORS]: {
      [EComponentProperty.AVAILABILITY]: emptyMonitor,
      [EComponentProperty.QR_READER_AVAILABILITY]: emptyMonitor
    }
  },
  [EComponent.TOTEM]: {
    [EComponentProperty.AIFI_ZONE_ID]: ""
  }
};

const emptyServices: TStoreServices = {
  [EStoreService.ERP]: {
    [EStoreServiceProperty.STORE_ID]: "",
    [EStoreServiceProperty.SERVICE_KEY]: "",
    [EStoreServiceProperty.IS_ENABLED]: undefined
  },
  [EStoreService.NANO_MONITOR]: {
    [EStoreServiceProperty.VERIFICATION_APPROVER_EMAIL]: ""
  }
};

export const emptyBase = {
  [EDay.MON]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [EDay.TUE]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [EDay.WED]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [EDay.THU]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [EDay.FRI]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [EDay.SAT]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  },
  [EDay.SUN]: {
    [EProperty.OPENS_AT]: "",
    [EProperty.CLOSES_AT]: ""
  }
};

export const initialStore: TStore = {
  [EStore.STORE_ID]: "",
  [EStore.CHAIN_ID]: "",
  [EStore.EXTERNAL_STORE_ID]: "",
  [EStore.NAME]: "",
  [EStore.TYPE]: EStoreType.REGULAR,
  [EStore.STATE]: EStoreState.PRE_LIVE,
  [EStore.ADDRESS]: "",
  [EStore.MANAGER_EMAIL]: "",
  [EStore.PARAMETERS]: emptyParameters,
  [EStore.COMPONENTS]: emptyComponents,
  [EStore.SERVICES]: emptyServices,
  [EStore.OPENING_SCHEDULE]: null
};
