import { useEffect } from "react";
import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Flex
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteGroup,
  AutoCompleteGroupTitle,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  Item
} from "@choc-ui/chakra-autocomplete";
import { Form, Formik } from "formik";
import groupBy from "lodash/groupBy";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { PathParts } from "routes";
import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";
import RadioButtons from "components/radioButtons/RadioButtons";
import { useStoreContext } from "contexts/StoreContext";
import { StoreWithChainName } from "pages/stores/types";
import { TStore } from "types/configFields";

const validationSchema = Yup.object().shape({
  chosenStore: Yup.object()
    .nullable()
    .when("shouldUseExistingStore", {
      is: (value: string) => value === "yes",
      then: schema => schema.required("To pole jest wymagane"),
      otherwise: schema => schema.optional()
    })
});

interface CreateNewStoreModalProps {
  stores: StoreWithChainName[];
}

const CreateNewStoreModal = ({ stores }: CreateNewStoreModalProps) => {
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal
  } = useDisclosure();
  const navigate = useNavigate();
  const { setTemplateStore } = useStoreContext();
  const storesByType = groupBy(stores, "type");

  const handleSubmit = (values: { chosenStore: Nullable<TStore> }) => {
    if (values.chosenStore) {
      setTemplateStore(values.chosenStore);
    }

    navigate(PathParts.CREATE);
  };

  const handleFiltering = (
    query: string,
    optionValue: Item["value"],
    optionLabel: Item["label"]
  ) => optionLabel.toLowerCase().includes(query.toLocaleLowerCase());

  useEffect(() => {
    setTemplateStore(null);
  }, [setTemplateStore]);

  return (
    <>
      <Button onClick={openModal} leftIcon={<AddIcon />} p="0 15px" bg="white">
        Nowy sklep
      </Button>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Tworzenie nowego sklepu</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={{ chosenStore: null, shouldUseExistingStore: null }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, values, setFieldValue, submitForm }) => (
              <ModalBody display="flex" flexDirection="column" gap="1rem">
                <Form>
                  <Text>
                    Chcesz utworzyć nowy sklep na podstawie innego sklepu?
                  </Text>
                  <Flex gap="2rem" m="2rem 0">
                    <RadioButtons
                      name="shouldUseExistingStore"
                      label=""
                      buttons={[
                        <Button width="50%" value="yes">
                          TAK
                        </Button>,
                        <Button
                          width="50%"
                          value="no"
                          onClick={async () => {
                            await setFieldValue("chosenStore", null);
                            submitForm();
                          }}
                        >
                          NIE
                        </Button>
                      ]}
                    ></RadioButtons>
                  </Flex>
                  {values.shouldUseExistingStore === "yes" && (
                    <FieldWrapper
                      name="chosenStore"
                      label="Wybierz sklep"
                      labelSize="1rem"
                      errorMessage={errors["chosenStore"]}
                    >
                      <AutoComplete
                        openOnFocus
                        listAllValuesOnFocus
                        filter={handleFiltering}
                      >
                        <AutoCompleteInput
                          variant="outline"
                          placeholder="Wybierz..."
                        />
                        <AutoCompleteList>
                          {Object.entries(storesByType).map(
                            ([type, groupedStores], id) => (
                              <AutoCompleteGroup key={id}>
                                <AutoCompleteGroupTitle
                                  textTransform="capitalize"
                                  color="blue"
                                  ml="1rem"
                                  fontSize="0.9rem"
                                >
                                  {type}
                                </AutoCompleteGroupTitle>
                                {groupedStores.map(store => {
                                  const displayedLabel = `${store.parameters?.countryCode ? `${store.parameters?.countryCode} -` : ""} ${store.externalStoreId}`;

                                  return (
                                    <AutoCompleteItem
                                      ml="1.2rem"
                                      key={store.storeId}
                                      value={store}
                                      label={displayedLabel}
                                      textTransform="capitalize"
                                      onClick={() =>
                                        setFieldValue("chosenStore", store)
                                      }
                                    >
                                      {displayedLabel}
                                    </AutoCompleteItem>
                                  );
                                })}
                              </AutoCompleteGroup>
                            )
                          )}
                        </AutoCompleteList>
                      </AutoComplete>
                    </FieldWrapper>
                  )}
                  <Flex
                    gap="1rem"
                    mt="3rem"
                    flexDir="column"
                    alignItems="center"
                  >
                    {values["shouldUseExistingStore"] && (
                      <Button variant="solid" w="15rem" type="submit">
                        DALEJ
                      </Button>
                    )}
                    <Button variant="ghost" w="15rem" onClick={closeModal}>
                      ANULUJ
                    </Button>
                  </Flex>
                </Form>
              </ModalBody>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateNewStoreModal;
