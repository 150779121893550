import * as Yup from "yup";

import { EStoreService, EStoreServiceProperty } from "types/configFields";

import { requiredMessage } from "./consts";

export const servicesSchema = Yup.object()
  .optional()
  .shape({
    [EStoreService.ERP]: Yup.object()
      .nullable()
      .shape({
        [EStoreServiceProperty.STORE_ID]: Yup.string().required(requiredMessage)
      }),
    [EStoreService.NANO_MONITOR]: Yup.object()
      .nullable()
      .shape({
        [EStoreServiceProperty.VERIFICATION_APPROVER_EMAIL]: Yup.string().email(
          "Podaj poprawny adres email"
        )
      })
  });
